.App {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.automation-image {
  width: 50%;
  border-radius: 20px; /* Adjust this value to your liking */
}

.automation-text {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 24px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.App-header {
  display: flex;
  justify-content: center;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

